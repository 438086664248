import { FunctionComponent, Ref } from 'react';
import { Control, useController } from 'react-hook-form';

import { TextField } from '@limepayments/cosmic';

import { FormInputs } from './types';

export interface InputProps {
  id?: string;
  control: Control<FormInputs>;
  name: keyof FormInputs;
  label: string;
  defaultValue?: string;
  rules?: object;
  inputRef?: Ref<any>;
  inputComponent?: FunctionComponent;
  type?: string;
  required?: boolean;
  testId?: string;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement, Element>): void;
}

const Input = (props: InputProps) => {
  const {
    label,
    inputRef,
    inputComponent,
    type,
    required,
    testId,
    placeholder,
    autoFocus,
    disabled,
    onChange,
    onBlur,
    ...otherProps
  } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController(otherProps);

  return (
    <TextField
      {...inputProps}
      inputRef={inputRef}
      label={label}
      placeholder={placeholder}
      required={required}
      error={error?.message ?? ''}
      type={type ?? 'text'}
      helperText={error ? error.message : ''}
      testId={testId}
      autoFocus={autoFocus ?? false}
      onChange={(event) => {
        onChange?.(event);
        inputProps.onChange(event);
      }}
      onBlur={(event) => {
        onBlur?.(event);
        inputProps.onBlur();
      }}
      disabled={disabled}
    />
  );
};

export default Input;
