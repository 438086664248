import { firebaseApp } from 'api/firebase';
import { UserClaims } from 'api/types';
import LogRocket from 'logrocket';
import { Env, getEnv, getHost } from 'utils/env';

import { defaultInitOptions } from './logrocket.lib';

export { logRocketTrack } from './logrocket.lib';

export const getSessionUrl = () => new Promise<string>((resolve) => LogRocket.getSessionURL(resolve));

const getAppId = (env: Env): string | null => {
  if (env === 'dev') return null;
  return `gx6dpd/limepay-${env}`;
};

export const logRocketInit = async () => {
  const env = await getEnv();
  const rootHostname = await getHost();
  const appId = getAppId(env);

  if (appId) {
    LogRocket.init(appId, {
      ...defaultInitOptions,
      release: '1.0.0',
      rootHostname,
    });
  }
};

export const logRocketIdentify = async () => {
  const currentUser = firebaseApp.auth().currentUser;
  if (!currentUser) return;

  const idTokenResult = await currentUser.getIdTokenResult();
  if (!idTokenResult) return;

  const claims = idTokenResult.claims as unknown as UserClaims;
  LogRocket.identify(claims.limepay.customerId, {
    email: claims.email,
  });
};
