import styled from '@emotion/styled';
import { Heading, Text } from '@limepayments/cosmic';

type PersonalDetailsTitleType = {
  hasIdDocs: boolean;
};

const Wrapper = styled.div<{ hasIdDocs: boolean }>`
  margin-top: ${({ hasIdDocs }) => (hasIdDocs ? '40px' : '28px')};
`;

const PersonalDetailsTitle = ({ hasIdDocs }: PersonalDetailsTitleType) => (
  <Wrapper hasIdDocs={hasIdDocs}>
    {hasIdDocs && (
      <>
        <Heading variant="xxs" className="neutral-600" testId="personalDetailsTitle">
          Personal details
        </Heading>
        <Text
          variant="caption"
          className="neutral-600 personal-details-description"
          testId="personalDetailsDescription"
        >
          Your details must match what appears on your Government issued I.D.
        </Text>
      </>
    )}
  </Wrapper>
);

export default PersonalDetailsTitle;
