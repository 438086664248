import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .neutral-600 {
    color: rgb(var(--lp-colors-neutral-600));
  }
  .personal-details-description {
    margin-top: 0;
    margin-bottom: 24px;
  }
  .additional-details {
    margin: 16px 0;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiInputBase-root {
    padding: 0 16px;
  }
  .MuiFormControl-root {
    width: 100%;
    > div:first-of-type {
      background: rgb(var(--lp-colors-neutral-white));
      padding: 0 16px;
    }
  }
  .MuiAutocomplete-root {
    .MuiInput-root .MuiInput-input {
      padding-top: 10px;
    }
    .MuiInputLabel-shrink {
      transform: translate(0, 7px) scale(0.75);
    }
    .MuiFormControl-root {
      padding: 0;
      background: rgb(var(--lp-colors-neutral-white));
      .MuiInputLabel-root {
        left: 16px;
      }
      > div:first-of-type {
        background: transparent;
        padding: 0 16px !important;
        height: 56px;
        margin-top: 0;
      }
    }
  }
  .manually-enter-address {
    margin-top: -20px;
    margin-left: 15px;
    font-size: var(--lp-font-size-caption);
  }
  .MuiFormHelperText-root.Mui-error {
    padding-left: 16px;
  }
  .MuiInputBase-input.Mui-disabled {
    opacity: 0.5;
  }
`;

export const LineWrapper = styled.div`
  @media only screen and (min-width: 414px) {
    display: flex;
    justify-content: space-between;

    & > div {
      margin-right: 30px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  @media only screen and (max-width: 415px) {
    display: contents;
  }
`;

export const LineHalfWrapper = styled.div`
  width: 50%;
  .MuiFormControl-root {
    padding-right: 15px;
  }
`;

export const MessageBar = styled.div`
  margin: 10px 0;
  .error-msg-text {
    line-height: var(--lp-line-height-body-3);
    font-size: var(--lp-font-size-body-3);
  }
`;

export const GoogleAddress = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInput-root > .MuiInput-input {
    margin-bottom: 48px;
    padding-bottom: 5px !important;
  }

  .pac-target-input:not(:-webkit-autofill) {
    animation-name: mui-auto-fill-cancel;
  }
`;

export const IdSelection = styled.div`
  .MuiSelect-select.MuiSelect-select {
    padding: 8px 24px 19px 0;
  }
  .label {
    color: rgba(var(--lp-colors-neutral-600), 1);
    font-size: var(--lp-font-size-body-2);
    font-family: var(--lp-fonts-body-2);
    font-weight: var(--lp-font-weight-body-2);
    letter-spacing: var(--lp-letter-spacing-body-2);
    line-height: var(--lp-line-height-body-2);
    padding-top: var(--lp-space-tiny);
  }
  .MuiFormLabel-root.Mui-focused {
    color: rgba(var(--lp-colors-primary), 1);
  }
  .MuiInput-underline:before {
    border-bottom: var(--lp-border-widths-thin) var(--lp-border-styles-solid) rgba(var(--lp-colors-neutral-200), 1);
  }
  .MuiInput-underline:after {
    border-bottom: var(--lp-border-widths-thick) var(--lp-border-styles-solid) rgba(var(--lp-colors-primary), 1);
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: var(--lp-border-widths-thick) var(--lp-border-styles-solid) rgba(var(--lp-colors-neutral-200), 1);
  }
`;

export const SubmitWrapper = styled.div`
  margin-top: 16px;
`;

export const MarginWrapper = styled.div`
  margin-top: 24px;
`;

export const SelectWrapper = styled.div`
  margin-top: 24px;
  .MuiInputLabel-root {
    left: 16px;
  }
  .MuiFormControl-root {
    background: rgb(var(--lp-colors-neutral-white));
  }
  .MuiInputBase-root {
    background: transparent !important;
  }
`;
