import styled from '@emotion/styled';
import { Message, Text } from '@limepayments/cosmic';

type ErrorMessageType = {
  errorMsg: string;
  testId?: string;
};

const MessageBar = styled.div`
  margin: 10px 0;
`;

const ErrorMessage = ({ errorMsg, testId }: ErrorMessageType) => {
  if (!errorMsg) {
    return <></>;
  }

  return (
    <MessageBar className="error-msg">
      <Message type="inline" variant="error" dismissalType="none">
        <Text testId={testId || 'kycErrorMsg'} variant="body-3" className="error-msg-text">
          {errorMsg}
        </Text>
      </Message>
    </MessageBar>
  );
};

export default ErrorMessage;
