const StreetTypes = [
  { value: 'Alley', text: 'Alley' },
  { value: 'Arcade', text: 'Arcade' },
  { value: 'Avenue', text: 'Avenue' },
  { value: 'Boulevard', text: 'Boulevard' },
  { value: 'Bypass', text: 'Bypass' },
  { value: 'Circuit', text: 'Circuit' },
  { value: 'Close', text: 'Close' },
  { value: 'Corner', text: 'Corner' },
  { value: 'Court', text: 'Court' },
  { value: 'Crescent', text: 'Crescent' },
  { value: 'Cul - de - sac', text: 'Cul - de - sac' },
  { value: 'Drive', text: 'Drive' },
  { value: 'Esplanade', text: 'Esplanade' },
  { value: 'Green', text: 'Green' },
  { value: 'Grove', text: 'Grove' },
  { value: 'Highway', text: 'Highway' },
  { value: 'Junction', text: 'Junction' },
  { value: 'Lane', text: 'Lane' },
  { value: 'Link', text: 'Link' },
  { value: 'Mews', text: 'Mews' },
  { value: 'Parade', text: 'Parade' },
  { value: 'Place', text: 'Place' },
  { value: 'Ridge', text: 'Ridge' },
  { value: 'Road', text: 'Road' },
  { value: 'Square', text: 'Square' },
  { value: 'Street', text: 'Street' },
  { value: 'Terrace', text: 'Terrace' },
];

export default StreetTypes;
