import dayjs from 'dayjs';

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const DATE_FORMAT = 'DD/MM/YYYY';

export const validateDate = (value: string): string | boolean => {
  if (dayjs(value, DATE_FORMAT).format(DATE_FORMAT) === value) {
    return true;
  }
  return 'Please enter valid date';
};

export const validateAge = (value: string): string | boolean => {
  const today = dayjs(new Date());
  const dob = dayjs(value, DATE_FORMAT);
  const age = today.diff(dob, 'year');
  if (age >= 18) {
    return true;
  }
  return 'Must be over 18';
};
