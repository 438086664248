import { DocOptionType, IdDocOptionsType, KycIdDocType } from 'api/types';

const hasRemainingAttempts = (idDocType: KycIdDocType, idDocOptions: IdDocOptionsType[]): boolean =>
  !!idDocOptions?.some(
    (option) =>
      (option.idDocType === idDocType || (Array.isArray(option.idDocTypes) && option.idDocTypes.length > 0)) &&
      option.remainingAttempts,
  );

const toIdDocOptions = (idDocOptions: IdDocOptionsType[], country: 'AU' | 'NZ'): DocOptionType[] => {
  if (country === 'NZ') {
    return [
      {
        value: KycIdDocType.driversLicenceWithVersion,
        label: "Driver's Licence (NZ)",
        disabled: !hasRemainingAttempts(KycIdDocType.driversLicenceWithVersion, idDocOptions),
      },
      {
        value: KycIdDocType.passportWithExpiry,
        label: 'Passport (NZ)',
        disabled: !hasRemainingAttempts(KycIdDocType.passportWithExpiry, idDocOptions),
      },
    ];
  }

  return [
    {
      value: KycIdDocType.driversLicence,
      label: "Driver's Licence (AU)",
      disabled: !hasRemainingAttempts(KycIdDocType.driversLicence, idDocOptions),
    },
    {
      value: KycIdDocType.passport,
      label: 'Passport (AU)',
      disabled: !hasRemainingAttempts(KycIdDocType.passport, idDocOptions),
    },
  ];
};

export default toIdDocOptions;
