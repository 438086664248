import { Config, Params } from 'api/types';

export const isB2B = (params: Params, config: Config) => {
  if (
    (params.isB2B === true && !config.supportedCustomerTypes.includes('organisationcustomertype')) ||
    (params.isB2B === false && !config.supportedCustomerTypes.includes('consumercustomertype'))
  ) {
    throw Error(`Customer type not supported: ${params.isB2B ? 'B2B' : 'B2C'}`);
  }

  return (
    params.isB2B || config.supportedCustomerTypes.every((CustomerType) => CustomerType === 'organisationcustomertype')
  );
};
