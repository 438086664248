import { Heading, Text } from '@limepayments/cosmic';

type FormTitleType = {
  hasIdDocs: boolean;
};

const FormTitle = ({ hasIdDocs }: FormTitleType) => (
  <>
    <Heading variant="xs" className="neutral-600" testId="formTitle">
      Verify your identity
    </Heading>
    {hasIdDocs && (
      <Text className="neutral-600" variant="caption" testId="formSubtitle">
        An identity document is required to ensure your eligibility for this type of payment.
      </Text>
    )}
    {!hasIdDocs && (
      <>
        <Text className="neutral-600" variant="caption" testId="formSubtitle">
          Verification is required for first-time customers and those with expired credentials.
        </Text>
        <Text className="neutral-600" variant="caption" isEmphasised testId="formSubtitleEmphasised">
          Please enter your details as they appear on your Government issued I.D.
        </Text>
      </>
    )}
  </>
);

export default FormTitle;
