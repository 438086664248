import { logRocketIdentify } from 'utils/logrocket';

import { firebaseApp } from './firebase';

const signInWithCustomToken = async (tenantId: string, token: string) => {
  const firebaseAuth = firebaseApp.auth();
  firebaseAuth.tenantId = tenantId;
  await firebaseAuth.signInWithCustomToken(token);
  await logRocketIdentify();
};

export default signInWithCustomToken;
