import { Params, SearchParams } from 'api/types';

const toBoolean = (value?: string) => (value ? ['true', '1'].includes(value.toLowerCase()) : undefined);

const toParams = (searchParams: SearchParams): Params => ({
  sessionId: searchParams.sessionId ?? '',
  firstName: searchParams.fn ?? '',
  middleName: searchParams.mn ?? '',
  lastName: searchParams.ln ?? '',
  dateOfBirth: searchParams.dob ?? '',
  address: searchParams.addr ?? '',
  customToken: searchParams.token ?? '',
  publicKey: searchParams.key ?? '',
  primaryColour: searchParams.pc ?? '',
  title: searchParams.title ?? '',
  subTitle: searchParams.subTitle ?? '',
  isB2B: toBoolean(searchParams.isB2B),
});

export default toParams;
