import { logRocketTrack } from './logrocket';

export const getErrorMessage = (error: any): string => {
  const isText = (text: any): boolean => typeof text === 'string' && !!text.length;
  if (isText(error)) return error;
  if (isText(error?.message)) return error.message;
  return 'Unknown error';
};

const handleResponse = async <T = any>(res: Response): Promise<T> => {
  const requestId = res.headers.get('limepay-request-id') || undefined;
  logRocketTrack({
    requestId,
  });

  let body = await res.text();

  if (res.ok) {
    // success response
    return body.length ? JSON.parse(body) : body;
  } else {
    // error response
    let errorMessage = body;
    try {
      // attempt to parse json error
      const payload = JSON.parse(body);
      logRocketTrack({
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
        message: payload.message,
        tracer: payload.tracer,
      });
      errorMessage = getErrorMessage(payload);
    } finally {
      // throw error message
      throw Error(errorMessage);
    }
  }
};

export default handleResponse;
