import { nanoid } from 'nanoid';
import { getAprilApiHost, getLimepayApiHost } from 'utils/env';
import handleResponse from 'utils/handleResponse';
import { getSessionUrl, logRocketTrack } from 'utils/logrocket';

import {
  AddressComponentisationPayload,
  Config,
  KycCustomerOrDelegateRequest,
  KycCustomerOrDelegateResponse,
  Params,
} from './types';

export const sessionId = nanoid();

export type DefaultHeaders = Partial<{
  'Session-Correlation-Id': string;
  'Logrocket-Session-Url': string;
}>;

export let defaultHeaders: DefaultHeaders = {
  'Session-Correlation-Id': sessionId,
};

export const setDefaultHeaders = (params: Params) => {
  defaultHeaders = {
    ...defaultHeaders,
    'Session-Correlation-Id': params.sessionId || sessionId,
  };
  logRocketTrack({
    sessionCorrelationId: defaultHeaders['Session-Correlation-Id'],
  });
  getSessionUrl().then((sessionUrl) => (defaultHeaders['Logrocket-Session-Url'] = sessionUrl));
};

export const kycCustomerOrDelegate = async <Response = KycCustomerOrDelegateResponse>(
  customToken: string,
  payload: KycCustomerOrDelegateRequest,
): Promise<Response> => {
  const apiHost = await getAprilApiHost();

  return fetch(`${apiHost}/customers/enterprise/kyc`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      Authorization: `Bearer ${customToken}`,
    }),
  }).then(handleResponse);
};

export const getCheckoutConfig = async (publicKey: string): Promise<Config> => {
  const apiHost = await getLimepayApiHost();

  return fetch(`${apiHost}/config/checkout`, {
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);
};

export interface GetAddressDetailsPreflightResponse {
  address: string;
  componentised?: {
    unitNumber?: string;
    streetNumber: string;
    streetName: string;
    streetType?: string;
    suburb?: string;
    city: string;
    postcode: string;
    state?: string;
    country: string;
  };
  addressIdentifier?: string;
}

export const getAddressDetailsPreflight = (
  host: string,
  publicKey: string,
  payload: AddressComponentisationPayload,
): Promise<GetAddressDetailsPreflightResponse> =>
  fetch(`${host}/utility/address-componentisation`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      ...defaultHeaders,
      'Limepay-PublicKey': publicKey,
    }),
  }).then(handleResponse);
