import StatesAndTerritories from 'constants/StatesAndTerritories';
import StreetTypes from 'constants/StreetTypes';
import SupportedCountries from 'constants/SupportedCountries';

import { getAddressDetailsPreflight, kycCustomerOrDelegate } from 'api/api';
import {
  CustomerIdentificationType,
  DocOptionType,
  KycCustomerOrDelegateRequest,
  KycEligibility,
  KycIdDocType,
  PayTypeEligibility,
  UboDetails,
} from 'api/types';
import dayjs from 'dayjs';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getEnv } from 'utils/env';
import getCurrentUserToken from 'utils/getCurrentUserToken';
import { getErrorMessage } from 'utils/handleResponse';
import scrollToView from 'utils/scrollToView';
import sendMessage from 'utils/sendMessage';
import toIdDocOptions from 'utils/toIdDocOptions';
import { validateAge, validateDate } from 'utils/validateDate';
import validateIdNumber from 'utils/validateIdNumber';

import { Loader } from '@googlemaps/js-api-loader';
import { Button, Heading, Link, Select, Stack } from '@limepayments/cosmic';

import Date from './Date';
import ErrorMessage from './ErrorMessage';
import * as s from './Form.styles';
import FormTitle from './FormTitle';
import Input from './Input';
import PersonalDetailsTitle from './PersonalDetailsTitle';
import { FormInputs, FormType } from './types';

const getGoogleAddressComponent = (
  address_components: google.maps.GeocoderAddressComponent[],
  name: string,
  type?: 'short_name' | 'long_name',
) => {
  const result = address_components.find((i) => i.types.includes(name));
  const returnValue = type ? result?.[type] : result?.long_name;
  return returnValue || '';
};

export type IdType = 'dl' | 'passport' | '';

const getDefaultIdType = (docOptions: DocOptionType[]): IdType =>
  docOptions.reduce((idType: IdType, { value, disabled }) => {
    if (idType) return idType;
    if (!disabled) {
      if ([KycIdDocType.driversLicence, KycIdDocType.driversLicenceWithVersion].includes(value)) return 'dl';
      if ([KycIdDocType.passport, KycIdDocType.passportWithExpiry].includes(value)) return 'passport';
    }
    return '';
  }, '');

const Form = ({
  config,
  params,
  invalidToken,
  showInitIdDocOptions,
  showInitDoubleDocs,
  initDocOptions,
  initUboDetails,
}: FormType) => {
  const [addressIdentifier, setAddressIdentifier] = useState<string>('');
  const [payTypeEligibility, setPayTypeEligibility] = useState<PayTypeEligibility | null>(null);

  const { setValue, getValues, handleSubmit, control } = useForm<FormInputs>({
    defaultValues: {
      country: config.merchantTradingCountry,
    },
  });
  const [dlState, setDlState] = useState<string>('');
  const [idType, setIdType] = useState<IdType>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [disableMiddleName, setDisableMiddleName] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [docOptions, setDocOptions] = useState<DocOptionType[]>([]);
  const [uboDetails, setUboDetails] = useState<UboDetails | null>(null);
  const [showIdDocOptions, setShowIdDocOptions] = useState<boolean>(false);
  const [showDoubleDocs, setShowDoubleDocs] = useState<boolean>(false);
  const [manualAddressEntry, setManualAddressEntry] = useState<boolean>(false);
  const [isValidAddress, setIsValidAddress] = useState<boolean>(false);

  const updateIdType = (idType: IdType) => {
    setIdType(idType);
    if (showIdDocOptions) {
      // force ID Select component to re-mount:
      setShowIdDocOptions(false);
      setTimeout(() => setShowIdDocOptions(true), 0);
    }
  };

  useLayoutEffect(() => {
    setShowIdDocOptions(showInitIdDocOptions);
    setShowDoubleDocs(showInitDoubleDocs);
    setDocOptions(initDocOptions);
    setUboDetails(initUboDetails);
    setIdType(getDefaultIdType(initDocOptions));

    if (initUboDetails) {
      setValue('firstName', initUboDetails.givenName ?? '');
      setValue('middleName', initUboDetails.middleName ?? '');
      setValue('lastName', initUboDetails.familyName);
    }
  }, [showInitIdDocOptions, showInitDoubleDocs, initDocOptions, initUboDetails, setValue]);

  const hasIdDocs = useMemo(() => showDoubleDocs || showIdDocOptions, [showDoubleDocs, showIdDocOptions]);

  const addressRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    (async () => {
      if (params.address) {
        const { address, addressIdentifier, componentised } = await getAddressDetailsPreflight(
          config.apiBaseUri,
          params.publicKey,
          {
            address: params.address,
            addressIdentifier: '',
          },
        );
        if (componentised?.country === config.merchantTradingCountry) {
          setValue('unitNumber', componentised.unitNumber || '');
          setValue('streetNumber', componentised.streetNumber || '');
          setValue('streetName', componentised.streetName || '');
          setValue('streetType', componentised.streetType || '');
          setValue('suburb', componentised.suburb || '');
          setValue('city', componentised.city || '');
          setValue('postcode', componentised.postcode || '');
          setValue('state', componentised.state || '');
          setValue('country', componentised.country || '');
          setValue('address', address || '');
          setAddressIdentifier(addressIdentifier || '');
          setIsValidAddress(true);
        }
      }
    })();
  }, [config, params, setValue]);

  useEffect(() => {
    if (payTypeEligibility?.eligibility !== KycEligibility.idWithDocument) {
      return;
    }

    const { idDocOptions } = payTypeEligibility;

    // idDocOptions is empty array => all the attempts are exhausted => hide ids
    const _showIdDocOptions =
      Array.isArray(idDocOptions) &&
      idDocOptions.length > 0 &&
      idDocOptions.every((option) => option.idDocType !== null);

    const _showDoubleDocs =
      Array.isArray(idDocOptions) &&
      idDocOptions.length > 0 &&
      idDocOptions.every((option) => option.idDocTypes !== null);

    setShowIdDocOptions(!!_showIdDocOptions);
    setShowDoubleDocs(!!_showDoubleDocs);
  }, [payTypeEligibility]);

  const mountAddressAutocomplete = useCallback(
    (input: HTMLInputElement, onPlaceChanged: (autocomplete: google.maps.places.Autocomplete) => void) => {
      const loader = new Loader({
        apiKey: config.authApiKey,
        libraries: ['places'],
      });
      const country = config.merchantTradingCountry.toLowerCase();
      loader.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(input, {
          types: ['address'],
          fields: ['place_id', 'formatted_address', 'address_components'],
          componentRestrictions: { country },
        });
        autocomplete.addListener('place_changed', () => onPlaceChanged(autocomplete));
      });
    },
    [config.authApiKey, config.merchantTradingCountry],
  );

  useEffect(() => {
    if (addressRef.current) {
      const isNZ = config.merchantTradingCountry === 'NZ';
      mountAddressAutocomplete(addressRef.current, (autocomplete) => {
        const { address_components = [], formatted_address, place_id } = autocomplete.getPlace();
        setValue('unitNumber', getGoogleAddressComponent(address_components, 'subpremise'));
        setValue('streetNumber', getGoogleAddressComponent(address_components, 'street_number'));
        setValue('streetName', getGoogleAddressComponent(address_components, 'route'));
        setValue('streetType', '');
        setValue('suburb', getGoogleAddressComponent(address_components, isNZ ? 'sublocality' : 'locality'));
        setValue(
          'city',
          getGoogleAddressComponent(address_components, isNZ ? 'locality' : 'administrative_area_level_2'),
        );
        setValue('postcode', getGoogleAddressComponent(address_components, 'postal_code'));
        setValue('state', getGoogleAddressComponent(address_components, 'administrative_area_level_1', 'short_name'));
        setValue('country', getGoogleAddressComponent(address_components, 'country', 'short_name'));
        setValue('address', formatted_address || '');
        setAddressIdentifier(place_id || '');
        setIsValidAddress(true);
      });
    }
  }, [mountAddressAutocomplete, setValue, config.merchantTradingCountry]);

  useEffect(() => {
    (async () => {
      const env = await getEnv();
      setDisableMiddleName(!!uboDetails && env === 'prd');
    })();
  }, [uboDetails]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const isNZ = config.merchantTradingCountry === 'NZ';
    try {
      setErrorMsg('');
      setLoading(true);

      const customerIdentification: CustomerIdentificationType = {
        name: {
          givenName: data.firstName,
          middleName: data.middleName,
          familyName: data.lastName,
        },
        dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
        address: {
          address: data.address,
          addressIdentifier,
          componentised: {
            unitNumber: data.unitNumber,
            streetNumber: data.streetNumber,
            streetName: data.streetName,
            streetType: data.streetType,
            suburb: data.suburb,
            city: data.city,
            postcode: data.postcode,
            state: isNZ ? '' : data.state,
            country: data.country,
          },
        },
      };

      let kycIdType = '';
      if (isNZ) {
        if (idType === 'dl' || showDoubleDocs) {
          customerIdentification.driversLicenceWithVersion = {
            number: data.dlNumber,
            versionNumber: data.dlVersionNumber,
          };
          kycIdType = KycIdDocType.driversLicenceWithVersion;
        }
        if (idType === 'passport' || showDoubleDocs) {
          customerIdentification.passportWithExpiry = {
            number: data.passportNumber,
            expiryDate: dayjs(data.passportExpDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          };
          kycIdType = KycIdDocType.passportWithExpiry;
        }
      } else if (config.merchantTradingCountry === 'AU') {
        if (idType === 'dl' || showDoubleDocs) {
          customerIdentification.driversLicence = {
            number: data.dlNumber,
            state: dlState,
            documentNumber: data.dlDocumentNumber,
          };
          kycIdType = KycIdDocType.driversLicence;
        }
        if (idType === 'passport' || showDoubleDocs) {
          customerIdentification.passport = {
            number: data.passportNumber,
            country: 'AUS',
          };
          kycIdType = KycIdDocType.passport;
        }
      }

      const token = await getCurrentUserToken();

      const payload: KycCustomerOrDelegateRequest = config.isB2B
        ? { KycOrganisationCustomerDelegate: { customerIdentification } }
        : { EnterpriseKycConsumerCustomer: { customerIdentification } };

      const response = await kycCustomerOrDelegate(token, payload);

      const payTypeEligibility =
        'OrganisationDelegateKycResult' in response
          ? response.OrganisationDelegateKycResult.payTypeEligibility
          : response.ConsumerCustomerKycResult.payTypeEligibility;

      const _uboDetails =
        'OrganisationDelegateKycResult' in response ? response.OrganisationDelegateKycResult.uboDetails ?? null : null;

      const { eligibility, message, idDocOptions } = payTypeEligibility;
      const _idDocOptions = toIdDocOptions(idDocOptions || [], config.merchantTradingCountry);
      setPayTypeEligibility(payTypeEligibility);
      setLoading(false);
      setDocOptions(_idDocOptions);
      setUboDetails(_uboDetails);

      switch (eligibility) {
        case KycEligibility.allow:
          sendMessage(true);
          setErrorMsg('');
          break;

        case KycEligibility.deny:
          const denyMsg = message || "Sorry, we weren't able to determine your identity";
          setErrorMsg(denyMsg);
          scrollToView('.error-msg');
          sendMessage(false);
          break;

        case KycEligibility.idWithoutDocument:
          setIdType('');
          setErrorMsg(
            message ||
              'We were unable to verify the information you provided. Please check your details, including your address and identification documentation, and try again.',
          );
          handleManualAddressEntry();
          /** when call handleManualAddressEntry, it will show the manual address components
           * so the height will be larger. scrollToView wouldn't be able to reach the errorMsg
           * add setTimeout 0 to run it in the next slot after the state is already updated
           */
          setTimeout(() => scrollToView('.error-msg'), 0);
          break;

        case KycEligibility.idWithDocument:
          handleManualAddressEntry();

          if (_idDocOptions.every(({ disabled }) => disabled)) {
            setErrorMsg(message || "Sorry, we weren't able to verify your identity");
            setIdType('');
            setDisableSubmit(true);
            setTimeout(() => scrollToView('.error-msg'), 0);
            sendMessage(false);
            return;
          }

          if (idType === '' && !showDoubleDocs) {
            setErrorMsg(
              message ||
                'We were unable to verify the details you provided. Please select an identification method and try again.',
            );
            setTimeout(() => scrollToView('.error-msg'), 0);
            return;
          }

          if (showDoubleDocs) {
            setErrorMsg(
              message ||
                'We were unable to verify the information you provided. Please check your details, including your address and identification documentation, and try again.',
            );
            setTimeout(() => scrollToView('.error-msg'), 0);
            return;
          }

          const remainingAttempts = _idDocOptions.some(({ value, disabled }) => value === kycIdType && !disabled);
          if (remainingAttempts) {
            // retry current id
            setErrorMsg(
              message ||
                'We were unable to verify the information you provided. Please check your details, including your address and identification documentation, and try again.',
            );
          } else {
            // select another id
            updateIdType(getDefaultIdType(_idDocOptions));
            setErrorMsg(
              message ||
                'We were unable to verify the details you provided. Please select a different identification method and try again.',
            );
          }
          setTimeout(() => scrollToView('.error-msg'), 0);

          break;
      }
    } catch (e) {
      setErrorMsg(getErrorMessage(e));
      setLoading(false);
      scrollToView('.error-msg');
      sendMessage(false);
    }
  };

  const handleChangeIdType = (value: IdType) => {
    setIdType(value);
  };

  const handleChangeDlState = (value: string) => {
    setDlState(value);
  };

  const handleManualAddressEntry = () => {
    setManualAddressEntry(true);
    setAddressIdentifier('');
    setValue('address', '');
    setIsValidAddress(false);
  };

  return (
    <s.Wrapper>
      <FormTitle hasIdDocs={hasIdDocs} />
      <ErrorMessage errorMsg={errorMsg} />
      {(showIdDocOptions || showDoubleDocs) && (
        <s.IdSelection>
          <Heading variant="xxs" className="neutral-600 additional-details">
            I.D. document{showDoubleDocs ? 's' : ''}
          </Heading>
          {showIdDocOptions && (
            <s.SelectWrapper>
              <Select
                value={idType}
                options={[
                  {
                    text: `Driver's licence (${config.merchantTradingCountry})`,
                    value: 'dl',
                    disabled: docOptions.length > 0 && docOptions[0].disabled,
                  },
                  {
                    text: `Passport (${config.merchantTradingCountry})`,
                    value: 'passport',
                    disabled: docOptions.length > 0 && docOptions[1].disabled,
                  },
                ]}
                onChange={handleChangeIdType}
                label="ID document type"
                errorMessage=""
                testId="selectId"
              />
            </s.SelectWrapper>
          )}
          {(idType === 'dl' || showDoubleDocs) && (
            <s.MarginWrapper>
              <Input
                control={control}
                name="dlNumber"
                label={
                  showDoubleDocs
                    ? `Driver's licence number (${config.merchantTradingCountry})`
                    : "Driver's licence number"
                }
                defaultValue=""
                rules={{ required: 'Required', validate: { validIdNumber: validateIdNumber } }}
                testId="dlNumber"
              />
              {config.merchantTradingCountry === 'AU' && (
                <>
                  <s.MarginWrapper>
                    <Select
                      value={dlState}
                      options={StatesAndTerritories}
                      onChange={handleChangeDlState}
                      label="State/Territory"
                      errorMessage=""
                      showSearch={true}
                      testId="dlState"
                    />
                  </s.MarginWrapper>
                  <s.MarginWrapper>
                    <Input
                      control={control}
                      name="dlDocumentNumber"
                      label="Driver's licence document number"
                      defaultValue=""
                      rules={{ required: 'Required', validate: { validDocNumber: validateIdNumber } }}
                      testId="dlDocumentNumber"
                    />
                  </s.MarginWrapper>
                </>
              )}
              {config.merchantTradingCountry === 'NZ' && (
                <s.MarginWrapper>
                  <Input
                    control={control}
                    name="dlVersionNumber"
                    label="Driver's licence version number"
                    defaultValue=""
                    rules={{ required: 'Required', validate: { validIdNumber: validateIdNumber } }}
                    testId="dlVersionNumber"
                  />
                </s.MarginWrapper>
              )}
            </s.MarginWrapper>
          )}
          {(idType === 'passport' || showDoubleDocs) && (
            <s.MarginWrapper>
              <Input
                control={control}
                name="passportNumber"
                label={showDoubleDocs ? `Passport number (${config.merchantTradingCountry})` : 'Passport number'}
                defaultValue=""
                rules={{ required: 'Required', validate: { validIdNumber: validateIdNumber } }}
                testId="passportNumber"
              />
              {config.merchantTradingCountry === 'NZ' && (
                <s.MarginWrapper>
                  <Date
                    control={control}
                    name="passportExpDate"
                    label="Passport expiry date"
                    defaultValue=""
                    placeholder="DD/MM/YYYY"
                    rules={{ required: 'Required', validate: { validDate: validateDate } }}
                    testId="passportExpDate"
                  />
                </s.MarginWrapper>
              )}
            </s.MarginWrapper>
          )}
        </s.IdSelection>
      )}
      <PersonalDetailsTitle hasIdDocs={hasIdDocs} />
      <form autoComplete="off" onSubmit={handleSubmit<FormInputs>(onSubmit)}>
        <Stack spacing="sm">
          <Input
            control={control}
            name="firstName"
            label="Legal first name"
            defaultValue={params?.firstName || ''}
            rules={{ required: 'Required' }}
            testId="firstName"
            disabled={!!uboDetails}
          />

          <Input
            control={control}
            name="middleName"
            label="Middle name/s (optional)"
            defaultValue={params?.middleName || ''}
            testId="middleName"
            disabled={disableMiddleName}
          />

          <Input
            control={control}
            name="lastName"
            label="Legal last name"
            defaultValue={params?.lastName || ''}
            rules={{ required: 'Required' }}
            testId="lastName"
            disabled={!!uboDetails}
          />

          {!uboDetails && (
            <Date
              control={control}
              name="dateOfBirth"
              label="Date of birth"
              defaultValue={params?.dateOfBirth || ''}
              placeholder="DD/MM/YYYY"
              rules={{ required: 'Required', validate: { validDate: validateDate, validAge: validateAge } }}
              testId="dateOfBirth"
            />
          )}

          {manualAddressEntry ? (
            <>
              <Heading tagName="h2" variant="xxs" className="neutral-600">
                Registered address
              </Heading>
              <Select
                value={getValues('country')}
                options={SupportedCountries.filter((i) => i.value === config.merchantTradingCountry)}
                onChange={(value) => setValue('country', value)}
                label="Country"
                errorMessage=""
                showSearch={true}
                testId="country"
              />
              <s.LineWrapper>
                <Input control={control} name="unitNumber" label="Unit Number" testId="unitNumber" />
                <Input
                  control={control}
                  name="streetNumber"
                  label="Street Number"
                  rules={{ required: 'Required' }}
                  testId="streetNumber"
                />
              </s.LineWrapper>
              <Input
                control={control}
                name="streetName"
                label="Street Name"
                rules={{ required: 'Required' }}
                testId="streetName"
              />
              <s.LineWrapper>
                <Select
                  value={getValues('streetType')}
                  options={StreetTypes}
                  onChange={(value) => setValue('streetType', value)}
                  label="Street Type"
                  errorMessage=""
                  showSearch={true}
                  testId="streetType"
                />
                <Input control={control} name="suburb" label="Suburb" testId="suburb" />
              </s.LineWrapper>
              <s.LineWrapper>
                <Input control={control} name="city" label="City" rules={{ required: 'Required' }} testId="city" />
                {getValues('country') !== 'NZ' && (
                  <Select
                    value={getValues('state') || ''}
                    options={StatesAndTerritories}
                    onChange={(value) => setValue('state', value)}
                    label="State"
                    errorMessage=""
                    showSearch={true}
                    testId="state"
                  />
                )}
              </s.LineWrapper>
              <s.LineHalfWrapper>
                <Input
                  control={control}
                  name="postcode"
                  label="Post Code"
                  rules={{ required: 'Required' }}
                  testId="postcode"
                />
              </s.LineHalfWrapper>
            </>
          ) : (
            <s.GoogleAddress>
              <Input
                control={control}
                name="address"
                label="Registered address"
                defaultValue=""
                rules={{ validate: () => isValidAddress || 'Required' }}
                inputRef={addressRef}
                testId="address"
                onChange={() => {
                  setAddressIdentifier('');
                  setIsValidAddress(false);
                }}
                onBlur={() => {
                  if (!isValidAddress) setValue('address', '');
                }}
              />
            </s.GoogleAddress>
          )}
          {!manualAddressEntry && (
            <Link size="small" className="manually-enter-address" onClick={handleManualAddressEntry}>
              Manually enter address
            </Link>
          )}
          <ErrorMessage errorMsg={errorMsg} testId="kycErrorMsgBottom" />
          <s.SubmitWrapper>
            <Button
              className="lp-w-full"
              variant="primary"
              size="large"
              isLoading={loading}
              disabled={
                (payTypeEligibility?.eligibility === KycEligibility.idWithDocument &&
                  idType === '' &&
                  !showDoubleDocs) ||
                (idType === 'dl' && !dlState && config.merchantTradingCountry === 'AU') ||
                disableSubmit ||
                loading ||
                invalidToken
              }
              testId="submitButton"
            >
              Submit
            </Button>
          </s.SubmitWrapper>
        </Stack>
      </form>
    </s.Wrapper>
  );
};

export default Form;
