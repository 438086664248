import styled from '@emotion/styled';
import { Spinner } from '@limepayments/cosmic';

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--lp-colors-neutral-white));
  opacity: 0.9;
  z-index: 9999;
`;

type SpinnerComponentType = {
  loading: boolean;
};

const SpinnerComponent = ({ loading }: SpinnerComponentType) => {
  if (!loading) {
    return <></>;
  }

  return (
    <Wrapper>
      <Spinner variant="simple" isVisible />
    </Wrapper>
  );
};

export default SpinnerComponent;
