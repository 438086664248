import '@limepayments/cosmic/build/css/reset.css';
import '@limepayments/cosmic/build/css/styles.css';

import { getCheckoutConfig, kycCustomerOrDelegate, setDefaultHeaders } from 'api/api';
import { firebaseApp } from 'api/firebase';
import signInWithCustomToken from 'api/signInWithCustomToken';
import { Config, DocOptionType, KycCustomerOrDelegateRequest, KycEligibility, Params, UboDetails } from 'api/types';
import { useEffect, useState } from 'react';
import getCurrentUserToken from 'utils/getCurrentUserToken';
import { getErrorMessage } from 'utils/handleResponse';
import { isB2B } from 'utils/isB2B';
import { logRocketTrack } from 'utils/logrocket';
import scrollToView from 'utils/scrollToView';
import sendMessage from 'utils/sendMessage';
import toIdDocOptions from 'utils/toIdDocOptions';
import toParams from 'utils/toParams';
import toSearchParams from 'utils/toSearchParams';

import { AppWrapper } from './App.styles';
import ErrorMessage from './ErrorMessage';
import Form from './Form';
import SpinnerComponent from './SpinnerComponent';

const App = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<Config | null>(null);
  const [params, setParams] = useState<Params | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showInitIdDocOptions, setShowInitIdDocOptions] = useState<boolean>(false);
  const [showInitDoubleDocs, setShowInitDoubleDocs] = useState<boolean>(false);
  const [initDocOptions, setInitDocOptions] = useState<DocOptionType[]>([]);
  const [initUboDetails, setInitUboDetails] = useState<UboDetails | null>(null);

  useEffect(() => {
    const _searchParams = toSearchParams(window.location.search);
    const _params = toParams(_searchParams);
    setDefaultHeaders(_params);
    setParams(_params);

    //https://stackoverflow.com/questions/57173292/iframeresize-is-not-defined-loaded-via-cdn-in-js-file
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.contentWindow.min.js';
    script.async = true;
    document.body.append(script);
  }, []);

  useEffect(() => {
    if (!params?.publicKey || !params?.customToken) {
      return;
    }
    const getConfigAndInitialKyc = async () => {
      try {
        setLoading(true);
        const _config = await getCheckoutConfig(params.publicKey);
        _config.isB2B = isB2B(params, _config);
        logRocketTrack({
          merchantId: _config.merchantId,
          marketplaceId: _config.marketplaceId,
        });
        setConfig(_config);
        firebaseApp.initializeApp({ apiKey: _config.authApiKey, authDomain: _config.authDomain }).auth();

        await signInWithCustomToken(_config.tenantId, params.customToken);

        const token = await getCurrentUserToken();

        const payload: KycCustomerOrDelegateRequest = _config.isB2B
          ? { KycOrganisationCustomerDelegate: { customerIdentification: {} } }
          : { EnterpriseKycConsumerCustomer: { customerIdentification: {} } };

        const response = await kycCustomerOrDelegate(token, payload);

        const payTypeEligibility =
          'OrganisationDelegateKycResult' in response
            ? response.OrganisationDelegateKycResult.payTypeEligibility
            : response.ConsumerCustomerKycResult.payTypeEligibility;

        const uboDetails =
          'OrganisationDelegateKycResult' in response
            ? response.OrganisationDelegateKycResult.uboDetails ?? null
            : null;

        const { eligibility, message, idDocOptions } = payTypeEligibility;
        const _idDocOptions = toIdDocOptions(idDocOptions || [], _config.merchantTradingCountry);
        setInitDocOptions(_idDocOptions);
        setInitUboDetails(uboDetails);
        setErrorMsg('');
        switch (eligibility) {
          case KycEligibility.allow:
            sendMessage(true);
            break;

          case KycEligibility.deny:
            const denyMsg = message || "Sorry, we weren't able to determine your identity";
            setErrorMsg(denyMsg);
            scrollToView('.error-msg');
            sendMessage(false);
            break;

          case KycEligibility.idWithoutDocument:
            setShowInitIdDocOptions(false);
            setShowInitDoubleDocs(false);
            break;

          case KycEligibility.idWithDocument:
            const hasRemainingAttempts = idDocOptions?.some(({ remainingAttempts }) => remainingAttempts > 0);
            if (hasRemainingAttempts) {
              const _showIdDocOptions =
                Array.isArray(idDocOptions) &&
                idDocOptions.length > 0 &&
                idDocOptions.every((option) => option.idDocType !== null);

              const _showDoubleDocs =
                Array.isArray(idDocOptions) &&
                idDocOptions.length > 0 &&
                idDocOptions.every((option) => option.idDocTypes !== null);

              setShowInitIdDocOptions(_showIdDocOptions);
              setShowInitDoubleDocs(_showDoubleDocs);
            } else {
              setShowInitIdDocOptions(false);
              setShowInitDoubleDocs(false);
              setErrorMsg('Sorry, no identification attempts remaining.');
            }
            break;
        }
      } catch (e) {
        setErrorMsg(getErrorMessage(e));
      } finally {
        setLoading(false);
      }
    };

    getConfigAndInitialKyc();
  }, [params]);

  return (
    <AppWrapper primaryColour={params?.primaryColour || ''}>
      <SpinnerComponent loading={loading} />
      <ErrorMessage errorMsg={errorMsg} />
      {config && params && (
        <Form
          config={config}
          params={params}
          invalidToken={errorMsg.length > 0}
          showInitIdDocOptions={showInitIdDocOptions}
          showInitDoubleDocs={showInitDoubleDocs}
          initDocOptions={initDocOptions}
          initUboDetails={initUboDetails}
        />
      )}
    </AppWrapper>
  );
};

export default App;
