export type Nullable<T> = T | null | undefined;

export type SearchParams = { [key: string]: string | undefined };

export type CustomerIdentificationType = {
  name?: {
    givenName?: string;
    middleName?: string;
    familyName: string;
  };
  dateOfBirth?: string;
  address?: {
    address: string;
    addressIdentifier?: string | null;
    componentised: {
      unitNumber?: string;
      streetNumber: string;
      streetName: string;
      streetType?: string;
      suburb?: string;
      city: string;
      postcode: string;
      state: string;
      country: string;
    };
  };
  driversLicence?: {
    number: string;
    state: string;
    documentNumber: string;
  };
  passport?: {
    number: string;
    country: string;
  };
  driversLicenceWithVersion?: {
    number: string;
    versionNumber: string;
  };
  passportWithExpiry?: {
    number: string;
    expiryDate: string;
  };
};

export type KycConsumerCustomer = {
  EnterpriseKycConsumerCustomer: {
    customerIdentification: CustomerIdentificationType;
    referenceCustomerId?: string;
  };
};

export type KycOrganisationCustomerDelegate = {
  KycOrganisationCustomerDelegate: {
    customerIdentification: CustomerIdentificationType;
    referenceCustomerId?: string;
  };
};

export type KycCustomerOrDelegateRequest = KycConsumerCustomer | KycOrganisationCustomerDelegate;

export enum KycIdDocType {
  driversLicence = 'driverslicence',
  passport = 'passport',
  driversLicenceWithVersion = 'driverslicencewithversion',
  passportWithExpiry = 'passportwithexpiry',
}

export enum KycEligibility {
  allow = 'allow',
  deny = 'deny',
  idWithoutDocument = 'idwithoutdocument',
  idWithDocument = 'idwithdocument',
}

export type DocOptionType = {
  value: KycIdDocType;
  label: string;
  disabled: boolean;
};

export type IdDocOptionsType = {
  idDocType: KycIdDocType | null;
  idDocTypes: KycIdDocType[] | null;
  remainingAttempts: number;
};

export type PayTypeEligibility = {
  eligibility: KycEligibility;
  message: string | null;
  idDocOptions: IdDocOptionsType[] | null;
};

export type UboDetails = {
  givenName?: string;
  middleName?: string;
  familyName: string;
  address: {
    unitNumber?: string;
    streetNumber: string;
    streetName: string;
    streetType?: string;
    suburb?: string;
    city: string;
    postcode: string;
    state?: string;
    country: string;
  };
};

export type ConsumerCustomerKycResult = {
  ConsumerCustomerKycResult: {
    payTypeEligibility: PayTypeEligibility;
  };
};

export type OrganisationDelegateKycResult = {
  OrganisationDelegateKycResult: {
    payTypeEligibility: PayTypeEligibility;
    uboDetails?: UboDetails;
  };
};

export type KycCustomerOrDelegateResponse = ConsumerCustomerKycResult | OrganisationDelegateKycResult;

export type Params = {
  sessionId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  address: string | undefined;
  customToken: string;
  publicKey: string;
  primaryColour: string;
  title: string;
  subTitle: string;
  isB2B?: boolean;
};

export interface Config {
  apiBaseUri: string;
  merchantId: string;
  marketplaceId?: string;
  tenantId: string;
  authApiKey: string;
  authDomain: string;
  googleProjectApiKey: string;
  veryGoodSecurityVaultEnvironment: 'live' | 'sandbox';
  veryGoodSecurityVaultId: string;
  tcUrl: string;
  privacyUrl: string;
  minPayPlanAmount: number;
  maxPayPlanAmountConfirmedCustomers: number;
  maxPayPlanAmountUnconfirmedCustomers: number;
  isB2B: boolean;
  supportedCustomerTypes: Array<'consumercustomertype' | 'organisationcustomertype'>;
  displayCustomerSavedPaymentMethodsInCheckout: boolean;
  allowHideFullPay: boolean;
  merchantTradingCountry: 'AU' | 'NZ';
}

export interface AddressComponentisationPayload {
  address: string;
  addressIdentifier?: string;
}

export interface UserClaims {
  email: string;
  limepay: {
    customerAccountId: string;
    customerId: string;
    merchantId: string;
  };
}
