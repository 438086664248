const SupportedCountries = [
  {
    value: 'AU',
    text: 'Australia',
  },
  {
    value: 'NZ',
    text: 'New Zealand',
  },
];

export default SupportedCountries;
